import React, { useState } from 'react';

import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

import { config } from 'config';

import { useRouter } from 'apis/history';

import { Text } from 'components';

import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import Call from '../../assets/icons/call.svg';

import Layout from 'layouts/Layout';

const TitleContainer = styled.div`
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 2.375rem;
  @media ${tablet} {
    font-size: 1.625rem;
  }
`;

const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1204px;
  margin: auto;
  padding: 4.5rem 1rem;
  @media ${tablet} {
    flex-direction: column;
    gap: 3rem;
    padding: 3rem 1rem;
  }
`;

const LeftSide = styled.div`
  @media ${tablet} {
    max-width: 32.625rem;
    width: 100%;
    margin: 0 auto;
  }
`;

const LeftSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const HomeLogo = styled(Home)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const CallLogo = styled(Call)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const LocationLogo = styled(Location)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const SmsLogo = styled(SMS)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const ItemWrap = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

const TextWrap = styled.div``;

const Title = styled(Text)`
  font-size: 1.125rem;
  font-weight: 600;
`;

const LeftSubTitle = styled(Text)`
  padding-top: 0.688rem;
`;

const RightSide = styled.div`
  max-width: 32.625rem;
  width: 100%;
  @media ${tablet} {
    margin: 0 auto;
  }
`;

const RightSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.5rem 1rem;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 4.5rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
    margin-bottom: 0;
  }
`;

const QuizTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    max-width: 32.625rem;
    width: 100%;
    font-size: 1.125rem;
  }
`;

const Contact = () => {
  const { goToLanding } = useRouter();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'NA',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderBottomComponent = () => {
    if (error) {
      return (
        <>
          <ErrorText>There was an issue sending your email.</ErrorText>
          <StyledButton type="submit">Submit</StyledButton>
        </>
      );
    }
    return (
      <StyledButton disabled={loading ? true : false} type="submit">
        Submit
      </StyledButton>
    );
  };

  return (
    <Layout
      seoTitle="Find out more about Nordastro"
      seoName="Find out more about Nordastro"
      seoDescription="Leave a message and get in touch with us"
      hasDivider={true}
      whiteFooter={true}
    >
      <ContactSection>
        <LeftSide>
          <LeftSideTitle>Get in touch with us</LeftSideTitle>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company</Title>
              <LeftSubTitle>Medical Score UAB</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company code</Title>
              <LeftSubTitle>305445861</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <SmsLogo />
            <TextWrap>
              <Title>Email address</Title>
              <LeftSubTitle>help@nordastro.com</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address of registration</Title>
              <LeftSubTitle>
                J. Zemgulio str. 46, Panevezys, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address</Title>
              <LeftSubTitle>
                Antakalnio str. 17, Vilnius, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>
        </LeftSide>
        <RightSide>
          <RightSideTitle>Leave us a message</RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Email <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
              placeholder="Please enter your email"
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
              placeholder="Please enter a subject"
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              value={formData.message || ''}
              placeholder="Your message"
            />
            {success ? (
              <SuccessText>Message was sent successfully!</SuccessText>
            ) : (
              <Note>
                Please enter the details of your request. A member of our team
                will respond as soon as possible.
              </Note>
            )}
            {!success && renderBottomComponent()}
          </form>
        </RightSide>
      </ContactSection>
      <QuizContainer>
        <QuizTitle>Get your fully personalized astrology book</QuizTitle>
        <QuizButton onClick={() => goToLanding()}>Take the quiz</QuizButton>
      </QuizContainer>
    </Layout>
  );
};

export default Contact;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rgb(6, 194, 112);
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rrgb(255, 59, 59);
`;

const Note = styled.div`
  color: #000;
  font-size: 0.875rem;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  background: ${({ disabled }) => (disabled ? '#EBEBF0' : '#F7C800')};
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  align-items: center;
  border: 0;
  color: #000;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 6.25rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.027rem;
  width: 100%;
  margin-top: 2rem;
  @media ${tablet} {
    margin: 2rem auto 0;
    max-width: 21.4375rem;
    display: block;
  }
`;

const QuizButton = styled(StyledButton)`
  max-width: 21.4375rem;
  width: 100%;
  @media ${tablet} {
  }
`;

const StyledLabel = styled.label`
  color: #1c1c28;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  -webkit-appearance: none;
  ::placeholder {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4375rem;
  }
`;

const StyledTextArea = styled.textarea`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 0.75rem;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  min-height: 144px;
  ::placeholder {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4375rem;
  }
`;
